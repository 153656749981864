import { useState, useEffect } from "react";
import './DropInfo.css';
import admin from '../../Images/admin.png';
import up from '../../Images/up.png';
import arrowLeftB from '../../Images/arrowleftb.png';

function DropInfo(){
    const [openInfo, setOpenInfo] = useState(false);

    const openBoxInfo = () => {
        if(!openInfo){
            document.getElementById('dropinfo').classList.add('hosting-drop-info-open-main');
            setOpenInfo(true);
        }else{
            document.getElementById('dropinfo').classList.remove('hosting-drop-info-open-main');
            setOpenInfo(false);
        }
    }

    return (
        <div className='hosting-drop-info' id="dropinfo">
            <div onClick={openBoxInfo} className='hosting-drop-info-header pointer d-flex flex-row justify-content-between align-items-center'>
                <div>
                    <img src={up} className='hosting-drop-info-up'/>
                </div>
                <div className='d-flex flex-row justify-content-between align-items-center'>
                    <div>
                        <p className='hosting-drop-info-text c-white bold'>اطلاعات شما</p>
                    </div>
                    <div>
                        <img src={admin} className="hosting-drop-info-icon"/>
                    </div>
                </div>
            </div>
            <div className='hosting-drop-info-main-in d-flex flex-column align-items-end w-100'>
                <div>
                    <p className='d-flex align-items-center hosting-drop-info-main-item-main'><span className='c-white'>عطا حیدری</span></p>
                </div>
                <div>
                    <p className='d-flex align-items-center hosting-drop-info-main-item-main-en'><span className='c-white-80'>Islamic Republic Of Iran</span></p>
                </div>
                <div>
                    <button className="btn-white-info"><span><img  className="btn-info-icon" src={arrowLeftB} /></span>بروز رسانی</button>
                </div>
            </div>
        </div>
    );
}

export default DropInfo;