import "./Locker.css";
import lock from "../../Images/Lock.png";

function Locker(props) {
  const { title, caption } = props;
  return (
    <div className="crypto-lock-card">
      <div className="w-100 d-flex justify-content-center">
        <div className="w-100 sec-head d-flex justify-content-center flex-row">
          <div className="secin-card1 justify-content-center align-items-center d-flex flex-column">
            <div className="call-container d-flex justify-content-center align-items-center">
              <div>
                <img className="call-icon" src={lock} alt="" />
              </div>
            </div>
            <div className="mtn-head c-white">
              <p>{title}</p>
            </div>
            <div className="mtn-foot c-white-70">
              <p>{caption}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Locker;
