import { useState, useEffect } from "react";
import './DropHand.css';
import cat from '../../Images/cat.png';
import up from '../../Images/up.png';
import {Link} from 'react-router-dom';

function DropHand(){
    const [openHand, setOpenHand] = useState(false);

    const openBoxHand = () => {
        if(!openHand){
            document.getElementById('dropHand').classList.add('hosting-drop-info-open-hand');
            setOpenHand(true);
        }else{
            document.getElementById('dropHand').classList.remove('hosting-drop-info-open-hand');
            setOpenHand(false);
        }
    }

    return (
        <div className='hosting-drop-info' id="dropHand">
            <div onClick={openBoxHand} className='hosting-drop-info-header pointer d-flex flex-row justify-content-between align-items-center'>
                <div>
                    <img src={up} className='hosting-drop-info-up'/>
                </div>
                <div className='d-flex flex-row justify-content-between align-items-center'>
                    <div>
                        <p className='hosting-drop-info-text c-white bold'>میانبر</p>
                    </div>
                    <div>
                        <img src={cat} className="hosting-drop-info-icon"/>
                    </div>
                </div>
            </div>
            <div className='hosting-drop-info-main-hand d-flex flex-column align-items-end w-100'>
                <div>
                    <Link to="/hosting-plan/shop" className="link1"><p className='d-flex align-items-center hosting-drop-info-main-item'><span className='c-white'>سفارس سرویس جدید</span><span className='circle-orange'></span></p></Link>
                </div>
                <div>
                    <Link to="/hosting-plan/domain" className="link1"><p className='d-flex align-items-center hosting-drop-info-main-item'><span className='c-white'>ثبت دامنه جدید</span><span className='circle-orange'></span></p></Link>
                </div>
                <div>
                    <Link to="/" className="link1"><p className='d-flex align-items-center hosting-drop-info-main-item'><span className='c-white'>خروج</span><span className='circle-orange'></span></p></Link>
                </div>
            </div>
        </div>
    );
}

export default DropHand;