import './ArzAmar.css';
import linerchart from '../../Images/linerchart.png'
import btcamar from '../../Images/btcamar.png';

function ArzAmar(props){
    return (
        <div className='arz-amar-container d-flex flex-column justify-content-between'>
            <div className='d-flex justify-content-start'>
                <div className='btc-amar-icon'>
                    <img src={btcamar} />
                </div>
                <div className='flex-fill d-flex justify-content-between w-100 mr-10'>
                    <div>
                       <p className='c-white f-16'>بیتکوین</p>
                       <p className='c-white-70 f-14'>$60.728.40</p>
                    </div>
                    <div>
                        <p className='f-14 c-main'>+1.36%</p>
                    </div>

                </div>
            </div>
            <div className='liner-chart-container'>
                <img src={linerchart} className='liner-chart-img' />
            </div>
        </div>
    );
}

export default ArzAmar;