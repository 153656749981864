import "./BannerArz.css";

import bannerArz from "../../Images/bannerArz.png";
import mouse from "../../Images/mouse.png";
import arrowdown from "../../Images/arrowdown.png";
import btc from "../../Images/btc.png";

import MainBtn from "../../Components/MainBtn/MainBtn";
import SecondBtn from "../../Components/SecondBtn/SecondBtn";
import MainBtnOpen from "../../Components/MainBtnOpen/MainBtnOpen";

import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

function BannerArz() {
  const [bzh, setBzh] = useState(0);

  const handleScroll = () => {
    document.getElementById("arz").scrollIntoView();
  };

  useEffect(() => {
    setBzh(window.innerHeight - 131);
  }, []);

  const [d1, setD1] = useState(false);
  const [d1name, setD1name] = useState("BITCOIN");

  const opend1 = () => {
    if (!d1) {
      document.getElementById("d1").classList.add("droper-open");
      document.getElementById("d1").classList.remove("droper-close");
      setD1(true);
    } else {
      document.getElementById("d1").classList.remove("droper-open");
      document.getElementById("d1").classList.add("droper-close");
      setD1(false);
    }
  };

  return (
    <>
      <div className="xs-d-none l-d-flex container-fluid banner-arz-container flex-column align-items-center">
        <div className="container h-100 d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-end align-items-lg-center w-100 banner-container-inside">
          <div className="banner-arz-image-container">
            <img src={bannerArz} />
          </div>
          <div className="banner-arz-title-container d-flex flex-column align-items-end">
            <div className="title-1">
              <p className="c-white bold rtl">خرید و فروش ارز های دیجیتال</p>
            </div>
            <div className="title-1">
              <p className="c-white bold rtl">و خدمات ارزی تبادل و تبدیل پول</p>
            </div>
            <div className="title-2">
              <p className="c-white bold rtl">
                <span className="c-main">خدمات حرفه ای ارزی</span>
              </p>
            </div>
            <div className="xs-d-block l-d-none title-text xs-mb-0 l-mb-60">
              <p className="c-white-70 rtl">
                خدمات کسب و کار خود را برای حرفه ای تر شدن به ما بسپاریدما با
                ارائه خدمات سطح بالا از طراحی سایت تا خدمات برندینگ به شما کمک
                خواهیم کرد
              </p>
            </div>
            <div className="d-flex justify-content-start w-100 mb-20 link-arz-container">
              <a className="link1" href="#information">
                <MainBtn text="فروش به ما" />
              </a>
              <div className="l-btn"></div>
              <Link className="xs-d-none l-d-block link1" to="/service">
                <SecondBtn text="خرید از ما" />
              </Link>
            </div>
            <div className="xs-d-none l-d-flex mb-10 banner-arz-change">
              <div className="mr-10 banner-arz-input">
                <input />
                <p className="c-white-70">BTC</p>
              </div>
              <div
                onClick={opend1}
                className="position-relative banner-arz-drop d-flex justify-content-center align-items-center"
              >
                <div className="mr-20">
                  <img src={arrowdown} />
                </div>
                <div className="mr-10">
                  <p className="c-white pt-2">{d1name}</p>
                </div>
                <div>
                  <img className="banner-arz-btc" src={btc} />
                </div>
                <div id="d1" className="droper-close">
                  <div
                    onClick={() => {
                      setD1name("TETHER");
                    }}
                    className="item"
                  >
                    <p className="c-white">TETHER</p>
                  </div>
                  <div
                    onClick={() => {
                      setD1name("SHIBA");
                    }}
                    className="item"
                  >
                    <p className="c-white">SHIBA</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="xs-d-none l-d-flex w-100 flex-row-reverse justify-content-between">
              <p className="rtl c-white">813,000تومان</p>
              <p className="rtl c-main">ادامه فرایند فروش</p>
            </div>
          </div>
        </div>
        <div className="xs-d-none l-d-flex mouse-arz-container d-flex flex-column align-items-center">
          <img className="mb-10 pointer" src={mouse} onClick={handleScroll} />
          <p className="c-white-20 default">اسکرول کنید</p>
        </div>
      </div>

      <div
        style={{ height: bzh + "px" }}
        className="overnone xs-d-flex l-d-none container-fluid banner-mob-container flex-column justify-content-start align-items-center"
      >
        <div className="h-100 flex-fill d-flex flex-column justify-content-start align-items-end">
          <div className="banner-arzmob-image-container">
            <img src={bannerArz} />
          </div>
          <div className="banner-design-title-container d-flex flex-column align-items-end">
            <div className="title-1">
              <p className="c-white rtl">خرید و فروش ارز های دیجیتال</p>
            </div>
            <div className="title-1">
              <p className="c-white rtl">و خدمات ارزی تبادل و تبدیل پول</p>
            </div>
            <div className="title-2">
              <p className="c-white rtl">
                <span className="c-main">خدمات حرفه ای ارزی</span>
              </p>
            </div>
            <div className="title-text">
              <p className="c-white-70 rtl">
                خدمات کسب و کار خود را برای حرفه ای تر شدن به ما بسپاریدما با
                ارائه خدمات سطح بالا از طراحی سایت تا خدمات برندینگ به شما کمک
                خواهیم کرد
              </p>
            </div>
            <div className="w-100 btn-banner-appmod-container">
              <a className="link1" href="#design">
                <MainBtnOpen text="بیشتر بدانید" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BannerArz;
