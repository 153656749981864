import React, { useEffect } from 'react';

function Login() {
    useEffect(() => {
        const iframe = document.createElement('iframe');
        iframe.src = 'https://dsh.emad.team/index.php?m=vo_reglogin';
        iframe.style.position = 'absolute';
        iframe.style.top = '0';
        iframe.style.left = '0';
        iframe.style.width = '100%';
        iframe.style.height = '100vh';
        iframe.style.border = 'none';
        document.body.appendChild(iframe);

        return () => {
            document.body.removeChild(iframe);
        };
    }, []);

    return null;
}

export default Login;
