import './HostingDropAct.css';
import admin from '../../Images/admin.png';
import up from '../../Images/up.png';

import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

function HostingDropAct(props){
    const [open, setOpen] = useState(false);

    const openBox = () => {
        if(!open){
            document.getElementById(props.id).classList.add('hosting-drop-act-open');
            setOpen(true);
        }else{
            document.getElementById(props.id).classList.remove('hosting-drop-act-open');
            setOpen(false);
        }
    }

    return (
        <div className='hosting-drop-act' id={props.id}>
            <div onClick={openBox} className='hosting-drop-act-header pointer d-flex flex-row justify-content-between align-items-center'>
                <div>
                    <img src={up} className='hosting-drop-act-up'/>
                </div>
                <div className='d-flex flex-row justify-content-between align-items-center'>
                    <div>
                        <p className='hosting-drop-act-text c-white bold'>دسته بندی</p>
                    </div>
                    <div>
                        <img src={admin} className="hosting-drop-act-icon"/>
                    </div>
                </div>
            </div>
            <div className='hosting-drop-act-main d-flex flex-column align-items-end w-100'>
                <div>
                    <p className='d-flex align-items-center hosting-drop-act-main-item'><span className='c-white'><Link className='link1' to ="/hosting-plan/domain">ثبت دامنه</Link></span><span className='circle-orange'></span></p>
                </div>
                <div>
                    <p className='d-flex align-items-center hosting-drop-act-main-item'><span className='c-white'><Link className='link1' to ="/hosting-plan/choose">انتقال دامنه</Link></span><span className='circle-orange'></span></p>
                </div>
                <div>
                    <p className='d-flex align-items-center hosting-drop-act-main-item'><span className='c-white'><Link className='link1' to ="/hosting-plan/buy">سبد خرید</Link></span><span className='circle-orange'></span></p>
                </div>
                <div>
                    <p className='d-flex align-items-center hosting-drop-act-main-item'><span className='c-white'><Link className='link1' to ="/">ویرایش حساب</Link></span><span className='circle-orange'></span></p>
                </div>
            </div>
        </div>
    );
}

export default HostingDropAct;