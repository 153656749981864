import './PostCard.css';
import MainBtn from '../MainBtn/MainBtn';
import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import axios from 'axios';
import jalaali from 'jalaali-js';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function PostCard(props) {
    const [itemwidth, setItemwidth] = useState('');
    const [categoryNames, setCategoryNames] = useState('');
    const [jalaliDate, setJalaliDate] = useState('');

    useEffect(() => {
        if (props.width === 'xx') {
            setItemwidth('100%');
        } else if (props.width === 'x' || props.width === 'e') {
            setItemwidth('540px');
        }

        // دریافت نام دسته‌بندی‌ها
        const fetchCategories = async () => {
            try {
                const categoryNames = await Promise.all(props.categories.map(async (catId) => {
                    const categoryResponse = await axios.get(`https://emad.team/mag/wp-json/wp/v2/categories/${catId}`);
                    return categoryResponse.data.name;
                }));
                setCategoryNames(categoryNames.join(', '));
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        if (props.categories) {
            fetchCategories();
        }

        // تبدیل تاریخ میلادی به شمسی فقط در صورتی که تاریخ معتبر باشد
        if (props.date) {
            const gregorianDate = new Date(props.date);
            if (!isNaN(gregorianDate)) {
                const jalaaliDate = jalaali.toJalaali(gregorianDate);
                setJalaliDate(`${jalaaliDate.jy}/${jalaaliDate.jm}/${jalaaliDate.jd}`);
            } else {
                console.error('Invalid date format:', props.date);
            }
        }
    }, [props.width, props.date, props.categories]);

    const baseColor = "#222222";
    const highlightColor = "#444444"; // رنگ دیگر برای هایلایت

    return (
        <div className="post-card-box d-flex flex-column justify-content-between align-items-end" style={{ width: itemwidth ,margin: '10%' }}>
            <div className="main-img-post-card-container">
                {props.image ? (
                    <img className="main-img-post-card" src={props.image} alt={props.title} />
                ) : (
                    <Skeleton height={200} width="100%" baseColor={baseColor} highlightColor={highlightColor} />
                )}
                <div><span className="post-card-set c-white">{categoryNames || <Skeleton width={80} baseColor={baseColor} highlightColor={highlightColor} />}</span></div>
            </div>
            <div className="d-flex flex-column justify-content-between align-items-center w-100 post-card-bottom-container">
                <div className="d-flex flex-row-reverse justify-content-start w-100 post-ad-container">
                    <div>
                        <p className="post-card-date c-white-70">{jalaliDate || <Skeleton width={70} baseColor={baseColor} highlightColor={highlightColor} />}</p>
                    </div>
                </div>
                <div className="d-flex flex-row-reverse justify-content-start w-100 post-card-header-container">
                    {props.title ? (
                        <p className="post-card-header c-main">{props.title}</p>
                    ) : (
                        <Skeleton width="100%" baseColor={baseColor} highlightColor={highlightColor} />
                    )}
                </div>
                <div className="d-flex flex-row-reverse justify-content-start w-100 rtl post-card-text-container">
                    {props.excerpt ? (
                        <p className="post-card-text c-white-70" dangerouslySetInnerHTML={{ __html: props.excerpt }} />
                    ) : (
                        <Skeleton count={3} baseColor={baseColor} highlightColor={highlightColor} />
                    )}
                </div>
                <div className="d-flex flex-row-reverse justify-content-end w-100">
                    {props.slug ? (
                        <Link className="link1" to={`/blog/${props.slug}`}>
                            <MainBtn text="بیشتر بخوانید" />
                        </Link>
                    ) : (
                        <Skeleton width={100} height={30} baseColor={baseColor} highlightColor={highlightColor} />
                    )}
                </div>
            </div>
        </div>
    );
}

export default PostCard;
