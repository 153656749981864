import './HostingAddDomain.css';

function HostingAddDomain(){
    return(
    <>
        <div className="xs-d-none l-d-flex host-add-domain flex-column align-items-end">
            <div>
                <p className="host-add-txt">ثبت دامنه جدید</p>
            </div>
            <div className="host-add-footer d-flex justify-content-start align-self-start">
                <div>
                    <button className="host-add-btn1 c-white">انتقال</button>
                </div>
                <div>
                    <button className="host-add-btn2 c-white">ثبت نام</button>
                </div>
                <div>
                    <input className="host-add-input rtl c-white" type="text" />
                </div>
            </div>
       </div>

            <div className="xs-d-flex l-d-none host-add-domain flex-column align-items-end">
               <div>
                   <p className="host-add-txt">ثبت دامنه جدید</p>
               </div>
               <div className="w-100 host-add-footer d-flex flex-column-reverse justify-content-start align-self-start">
                   <div className=' w-100  d-flex justify-content-between flex-fill'>
                        <div className="flex-fill">
                            <button className="w-100 host-add-btn1-sm c-white">انتقال</button>
                        </div>
                        <div className='hosting-add-btn-space-sm'></div>
                        <div className="flex-fill">
                            <button className="w-100 host-add-btn2-sm c-white">ثبت نام</button>
                        </div>
                   </div>
                   <div className='flex-fill w-100 mb-20'>
                       <input className="host-add-input-sm rtl c-white" type="text" />
                   </div>
               </div>
          </div>
    </>
    );
}

export default HostingAddDomain;