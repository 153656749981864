import './HostingCardRight.css';

function HostingCardRight() {
    return (
        <div className="hosting-card-container d-flex flex-column">
           <div className="hosting-card-nav d-flex flex-row justify-content-between align-items-center">
                <div>
                    <button className="hosting-card-btn c-white pointer">بررسی پکیج ها</button>
                </div>
                <div>
                    <p className="hosting-txt-bold">افزودن هاست اشتراکی</p>
                </div>
           </div>
           <div>
                <p className="hosting-txt-footer1 rtl">از بین پکیج ها متنوع هاست اشتراکی انتخاب کنید</p>
                <p className="hosting-txt-footer2 rtl c-white">هر بودجه ای دارید،بر اساس آن ما پکیج پیشنهادی داریم</p>
           </div>
       </div>
    );
}

export default HostingCardRight;