import { useState, useEffect } from "react";
import './DropOtherAcount.css';
import cat from '../../Images/cat.png';
import up from '../../Images/up.png';
import {Link} from 'react-router-dom';

function DropOtherAcount(){
    const [openOtherAcount, setOpenOtherAcount] = useState(false);

    const openBoxOtherAcount = () => {
        if(!openOtherAcount){
            document.getElementById('dropotheracount').classList.add('hosting-drop-hand-open');
            setOpenOtherAcount(true);
        }else{
            document.getElementById('dropotheracount').classList.remove('hosting-drop-hand-open');
            setOpenOtherAcount(false);
        }
    }

    return (
        <div className='hosting-drop-info' id="dropotheracount">
            <div onClick={openBoxOtherAcount} className='hosting-drop-info-header pointer d-flex flex-row justify-content-between align-items-center'>
                <div>
                    <img src={up} className='hosting-drop-info-up'/>
                </div>
                <div className='d-flex flex-row justify-content-between align-items-center'>
                    <div>
                        <p className='hosting-drop-info-text c-white bold'>حساب های فرعی</p>
                    </div>
                    <div>
                        <img src={cat} className="hosting-drop-info-icon"/>
                    </div>
                </div>
            </div>
            <div className='hosting-drop-hand-main d-flex flex-column align-items-end w-100'>
                <div>
                    <p className='d-flex align-items-center hosting-drop-info-main-item inner-other-ac'><span className='c-white'>حساب فرعی یافت نشد</span></p>
                </div>
                <div>
                    <Link className="link1" to="/signup"><button className="btn-white-hand"><span></span>حساب جدید</button></Link>
                </div>
            </div>
        </div>
    );
}

export default DropOtherAcount;