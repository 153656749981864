import './HostingSelect.css';
import { useState } from "react";

function HostingSelect() {

    return (
        <div className="d-flex flex-wrap justify-content-end">
           <div>
                <button className="select-items c-white-70 pointer bold">other (1)</button>
           </div>
           <div>
                <button className="select-items c-white-70 pointer bold">other (1)</button>
           </div>
           <div>
                <button className="select-items c-white-70 pointer bold">other (1)</button>
           </div>
           <div>
                <button className="select-items c-white-70 pointer bold">other (1)</button>
           </div>
           <div>
                <button className="select-items-active c-white-70 pointer bold">popular (2)</button>
            </div>
       </div>
    );
}

export default HostingSelect;