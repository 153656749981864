import './Hosting.css';
import HostingLine from '../../Components/HostingLine/HostingLine.js';
import HostingDomain from '../../sections/HostingDomain/HostingDomain.js';
import Improve from '../../sections/Improve/Improve';
import MainForm from '../../sections/MainForm/MainForm';
import Footer from '../../sections/Footer/Footer';
import Header from '../../sections/Header/Header';
import HostingPanel from '../../sections/HostingPanel/HostingPanel';
import HostingBuy from '../../sections/HostingBuy/HostingBuy';
import HostingConfig from '../../sections/HostingConfig/HostingConfig';
import HostingDomainChoose from '../../sections/HostingDomainChoose/HostingDomainChoose';
import HostingShop from '../../sections/HostingShop/HostingShop';
import FooterMob from '../../sections/FooterMob/FooterMob';
import {useState, useEffect} from 'react';
import { Outlet, Link } from "react-router-dom";
import HeaderPanel from '../../sections/HeaderPanel/HeaderPanel';

function Hosting(){
    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    },[]);
    
    return(
        <div className='hosting-container'>
            <div className='xs-d-none l-d-block'>
                <Header />
            </div>
            <div className='xs-d-none l-d-block'>
                <div className='space-host-1'></div>
                <HostingLine />
                <div className='space-host-2'></div>
            </div>
            <div className='container-fluid'>
                <div className='hosting-outlit'>
                    <Outlet />
                </div>
                
                <div className='xs-d-block l-d-none hosting-footer-mob'>
                    <FooterMob />
                </div>      
            </div>
            <div className='xs-d-none l-d-block'>
                <div className='space-host-3'></div>
                <Improve />
                <div className='space-host-4'></div>
                <div className='container'>
                    <MainForm />
                </div>
                <div className='space-host-5'></div>
                <Footer />
            </div>
        </div>
    )
}

export default Hosting;