import FooterMob from '../../sections/FooterMob/FooterMob';
import HeaderInstagram from '../../sections/HeaderInstagram/HeaderInstagram';
import './InstaPopUp.css';
import up from '../../Images/up.png';
import down from '../../Images/arrowdown.png';
import MainBtnOpen from '../../Components/MainBtnOpen/MainBtnOpen';

function InstaPopUp(){
    return (
        <div className='insta-pop-container d-flex flex-column justify-content-center'>
            <div className='header-insta-pop'>
                <HeaderInstagram />
            </div>
            <div className='btn-insta-pop-container'>
                <div className='insta-pop-btn'>
                    <div className='insta-pop-btn-inner bg-main'>
                        <p className='c-white'>لایک پست</p>
                        <img src={up} className="instagram-up"/>
                    </div>
                </div>
            </div>
            <div className='instagram-form flex-fill'>
                <div className='drop-instagram'>
                    <p className='c-white-30 f-14'>تعداد لایک هارا انتخاب کنید</p>
                    <img src={down} className=''/>
                </div>
                <div className='drop-instagram'>
                    <p className='c-white-30 f-14'>نوع لایک هارا انتخاب کنید</p>
                    <img src={down} className=''/>
                </div>
                <input placeholder="لینک پست" type='text' className='input-instagram'/>
                <p className='c-main f-12 rtl'>
                    لطفا قبل از هر گونه سفارش اکانت خود را از حالت<span> Private </span>خارج کنید
                </p>
            </div>
            <div className='open-btn-instagram'>
                <MainBtnOpen text="ثبت سفارش"/>
            </div>
            <div>
                <FooterMob />
            </div>
        </div>
    );
}

export default InstaPopUp;