import './HostingBuy.css';
import HostingDropCat from '../../Components/HostingDropCat/HostingDropCat';
import HostingDropAct from '../../Components/HostingDropAct/HostingDropAct';
import DropBuy from '../../Components/DropBuy/DropBuy';
import DropBuyDrop from '../../Components/DropBuyDrop/DropBuyDrop';
import HeaderBuy from '../HeaderBuy/HeaderBuy';
import { useState } from 'react';

function HostingBuy() {
    const [opener, setOpener] = useState(false);

    const openeropen = () => {
        if(!opener){
            document.getElementById('opener').classList.remove('opener-hide');
            document.getElementById('opener').classList.add('opener-show');
            setOpener(true);
        }else{
            document.getElementById('opener').classList.add('opener-hide');
            document.getElementById('opener').classList.remove('opener-show');
            setOpener(false);
        }
    }

    return (
        <>
        <div className='xs-d-block l-d-none'>
            <HeaderBuy />
        </div>
        <div className='hosting-buy-container container-xl d-flex justify-content-between'>
            <div className='xs-d-none l-d-block'>
                <DropBuy />
            </div>
            <div className='w-100 service-content-container'>
                <div className='w-100 d-flex justify-content-end mb-30'>
                    <p className='c-white f-16 bold xs-d-none l-d-block'>مرور و پرداخت نهایی</p>
                </div>
                <div onClick={openeropen} className='d-flex flex-row-reverse justify-content-between align-items-center service-card-container'>
                    <div>
                        <p className='rtl c-main bold f-16'>سرویس  طلایی</p>
                        <p className='rtl c-white f-14'>سه گیگ فضا</p>
                        <p className='rtl c-main f-12'>domain.ir</p>
                    </div>
                    <div className='d-flex align-items-center'>
                        <div className='mr-16'>
                            <p className='c-white-30 f-20 pointer'>&times;</p>
                        </div>
                        <div>
                            <p className='d-flex c-white rtl'><span className='ml-4'>300,000</span><span className='bold'>تومان</span></p>
                            <p className='c-main-50'>قابل پرداخت</p>
                        </div>
                    </div>
                </div>
                <div className='w-100 d-flex justify-content-end mb-14'>
                    <p className='c-white f-12 bold'>کد تخفیف</p>
                </div>
                <div className='d-flex justify-content-between w-100'>
                    <div className='mr-16'>
                        <button className='hosting-buy-btn d-flex justify-content-center align-items-center'>&#10004;</button>
                    </div>
                    <div className='w-100'>
                        <input className="hosting-buy-input rtl" type="text" placeholder='کد تخفیف خود را وارد کنید' />
                    </div>
                </div>
            </div>
            <div className='xs-d-none l-d-block pt-20'>
                <HostingDropCat id="catbuy"/>
                <div className='mb-43'></div>
                <HostingDropAct id="actbuy"/>
            </div>
        </div>
        <div>
                <div className="xs-d-block l-d-none w-100 drop-buy-item-container-sm">
                    <div className="d-flex flex-row-reverse justify-content-between w-100 mb-30"> 
                        <span className="f-12 c-white-70 flex-filld-block"><span className="d-flex align-items-center f-12 c-white-70">سرویس طلایی سه گیگ <span className="circle-orange d-block ml-8"></span></span></span>
                        <span className='d-block c-white-70 f-12 d-flex'><span className='d-block mr-4'>تومان</span><span className='d-block'>300,000</span></span>
                    </div>
                    <div className="d-flex flex-row-reverse justify-content-between w-100 mb-30">
                        <span className="f-12 c-white-70 flex-filld-block"><span className="d-flex align-items-center f-12 c-white-70">ماهانه <span className="circle-orange d-block ml-8"></span></span></span>
                        <span className='d-block c-white-70 f-12 d-flex'><span className='d-block mr-4'>تومان</span><span className='d-block'>30,000</span></span>
                    </div>
                    <div className="d-flex flex-row-reverse justify-content-between w-100 mb-30">
                        <span className="f-12 c-white-70 flex-filld-block"><span className="d-flex align-items-center f-12 c-white-70">هزینه نصب <span className="circle-orange d-block ml-8"></span></span></span>
                        <span className='d-block c-white-70 f-12 d-flex'><span className='d-block mr-4'>تومان</span><span className='d-block'>0</span></span>
                    </div>
                    <div className="d-flex flex-row-reverse justify-content-between w-100 mb-30">
                        <span className="f-12 c-white-70 flex-filld-block"><span className="d-flex align-items-center f-12 c-white-70">تخفیف <span className="circle-orange d-block ml-8"></span></span></span>
                        <span className='d-block c-white-70 f-12 d-flex'><span className='d-block mr-4'>تومان</span><span className='d-block'>0</span></span>
                    </div>
                </div>
        </div>
        <div id="opener"  className='opener-hide'>
            <DropBuyDrop onChange={openeropen}/>
        </div>
        <div className='div-shrinker'>
        
        </div>
        </>
    );
}

export default HostingBuy;