import './HostingForm.css';

function HostingForm(){
    return (
        <div className="hosting-form-container d-flex flex-column-reverse flex-lg-row justify-content-center align-items-center">
            <div className='w-100'>
                <button className="hosting-form-btn c-white pointer">جستجو</button>
            </div>
            <div className='w-100'>
                <input type="text" className="hosting-form-input c-white rtl" />
            </div>
        </div>
    );
}

export default HostingForm;