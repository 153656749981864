import './HostingLine.css';
import line from '../../Images/line.png';
import {Link} from 'react-router-dom';

function HostingLine(){
    return (
        <div className='hosting-line' style={{backgroundImage: 'url('+line+')'}}>
            <div className='container h-100 d-flex justify-content-between align-items-center'>
                <div className='hosting-line-nav d-flex'>
                    <div className='hosting-line-nav-item'>
                        <Link className='link1' to="/contact"><p className='c-white-70 pointer'>تماس با ما</p></Link>
                    </div>
                    <div className='hosting-line-nav-item'>
                        <p className='c-white-70 pointer'>وضعیت شبکه</p>
                    </div>
                    <div className='hosting-line-nav-item'>
                        <p className='c-white-70 pointer'>مرکز آموزش</p>
                    </div>
                    <div className='hosting-line-nav-item'>
                        <p className='c-white-70 pointer'>اخبار</p>
                    </div>
                    <div className='hosting-line-nav-item'>
                        <p className='c-white-70 pointer'>فروشگاه</p>
                    </div>
                    <div className='hosting-line-nav-item'>
                        <p className='c-white'>ناحیه کاربری</p>
                    </div>
                </div>
                <div className='hosting-line-header d-flex flex-column align-items-end'>
                    <p className='hosting-line-header-fa c-white bold'>هاستینگ</p>
                    <p className='hosting-line-header-en c-white-70'>HOSTING</p>
                </div>
            </div>
        </div>
    );
}

export default HostingLine;