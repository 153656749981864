import './HostingPanel.css';
import PanelCards from '../PanelCards/PanelCards';
import PanelSearch from '../PanelSearch/PanelSearch';
import HostingCardMain from '../../Components/HostingCardMain/HostingCardMain';
import HostingAddDomain from '../../Components/HostingAddDomain/HostingAddDomain';
import DropInfo from '../../Components/DropInfo/DropInfo';
import DropHand from '../../Components/DropHand/DropHand';
import DropOtherAcount from '../../Components/DropOtherAcount/DropOtherAcount';
import FooterMob
 from '../FooterMob/FooterMob';
import HeaderPanel from '../HeaderPanel/HeaderPanel';
function HostingPanel() {
    return (
        <>
        <div className='xs-d-block l-d-none'>
            <HeaderPanel />
            <div className='space-host-6'></div>
        </div>
        <div className='container-xl d-flex flex-row justify-content-center'>
            <div className='w-100 hosting-panel-container w-100 d-flex flex-column'>
                <div className='xs-d-none l-d-flex mb-25 w-100 d-flex flex-column align-items-end'>
                    <p className='c-white bold mb-5'>خوش آمدید, عطا</p>
                    <p className='c-white'><span className='c-white-50 ml-2'>اعضا</span>/<span className='c-white mr-2'>پنل کاربری</span></p>
                </div>
                <div className='w-100 mb-20  main-cards-mob'>
                    <PanelCards />
                </div>
                <div className='mb-20 main-cards-mob'>
                    <PanelSearch />
                </div>
                <div className='d-flex flex-column w-100 main-cards-mob'>
                    <div className='w-100 d-flex flex-column flex-lg-row-reverse justify-content-between mb-20'>
                        <HostingCardMain header="محصولات / سرویس های فعال شما" btn="مشاهده همه" body="انگار هیچ سرویس و محصولی نزد ما ندارید.برای شروع هم‌اکنون یک سفارش ارسال کنید"/>
                        <div className='ml-16 xs-mb-20 l-mb-0'></div>
                        <HostingCardMain header="تیکت های اخیر" btn="ثبت تیکت جدید" body="هیچ تیکتی یافت نشد. اگر سوالی دارید تیکت جدید برای ما ارسال کنید"/>
                    </div>
                    <div className='w-100 d-flex flex-column-reverse flex-lg-row justify-content-between mb-20'>
                        <HostingCardMain header="آخرین اخبار" btn="مشاهده همه" body="هیچ اخباری برای نمایش وجود ندارد"/>
                        <div className='ml-16 xs-mb-20 l-mb-0'></div>
                        <div className='flex-fill w-100'>
                            <HostingAddDomain />
                        </div>
                    </div>
                </div>
            </div> 
            <div className='xs-d-none l-d-flex ml-30 flex-column align-items-end'>
                <div className="mb-30"></div>
                <DropInfo />
                <div className='mb-50'></div>
                <DropHand />
                <div className='mb-50'></div>
                <DropOtherAcount />
            </div>
        </div>
        </>
    );
}

export default HostingPanel;