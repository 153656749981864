import './PanelSearch.css';
import Search from '../../Images/Search.png';

function PanelSearch(){
    return (
        <div className="panel-search d-flex justify-content-center align-items-center w-100">
           <div className="panel-search-container d-flex justify-content-center align-items-center pointer">
                <img className="panel-search-icon" src={Search} />
           </div>
           <div className="w-100 d-flex justify-content-end">
                <input className="panel-search-input rtl c-white" placeholder="سوالات خود را جهت جستجو در بخش سوالات جستجو کنید ..." />
           </div>
        </div>
    );
}

export default PanelSearch;