import './PanelCards.css';
import PanelCard from '../../Components/PanelCard/PanelCard';

function PanelCards() {
    return (
        <div className='w-100 panel-cards-container d-flex flex-column-reverse flex-lg-row justify-content-center'>
            <div className='flex-fill'>
                <PanelCard text='صورت حساب ها' />
            </div>
            <div className='shrink-cards-panel'></div>
            <div className='flex-fill xs-mb-20 l-mb-0'>
                <PanelCard text='تیکت ها' />
            </div>
            <div className='shrink-cards-panel'></div>
            <div className='flex-fill xs-mb-20 l-mb-0'>
                <PanelCard text='دامنه ها' />
            </div>
            <div className='shrink-cards-panel'></div>
            <div className='flex-fill  xs-mb-20 l-mb-0'>
                <PanelCard text='سرویس ها' />
            </div>
            
        </div>
    );
}

export default PanelCards;