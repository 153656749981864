import './HostingCardMain.css';

function HostingCardMain(props) {
    return (
        <div className="hosting-card-container d-flex flex-column justify-content-between">
           <div className="hosting-card-nav d-flex flex-row justify-content-between align-items-center">
                <div>
                    <button className="hosting-card-btn c-white pointer">{props.btn}</button>
                </div>
                <div>
                    <p className="hosting-txt-bold">{props.header}</p>
                </div>
           </div>
           <div>
                <p className="hosting-txt-main-footer1 c-white rtl">{props.body}</p>
           </div>
       </div>
    );
}

export default HostingCardMain;