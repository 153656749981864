import './HostingConfig.css';
import down from '../../Images/down.png';
import HostingDropAct from '../../Components/HostingDropAct/HostingDropAct';
import HostingDropCat from '../../Components/HostingDropCat/HostingDropCat';
import BigDropBuy from '../../Components/BigDropBuy/BigDropBuy';
import HeaderConfig from '../HeaderConfig/HeaderConfig';
import { useState } from 'react';

function HostingConfig(){
    const [dropconfig, setDropconfig] = useState(false);
    const [num, setNum] = useState('10,000');

    const opendropconfig = () => {
        if(!dropconfig) {
            document.getElementById('dropconfig').classList.add("drop-config-open");
            document.getElementById('dropconfig').classList.remove("drop-config-close");
            setDropconfig(true);
        }else {
            document.getElementById('dropconfig').classList.remove("drop-config-open");
            document.getElementById('dropconfig').classList.add("drop-config-close");
            setDropconfig(false);
        }
    }
    return (
        <>
        <div className='xs-d-block l-d-none'>
            <HeaderConfig />
            <div className="header-config-space"></div>
        </div>
        <div className='container-xl hosting-config-container d-flex justify-content-between'>
            <div className='config-drop-buy xs-d-none l-d-block'>
                <BigDropBuy />
            </div>
            <div className='w-100 config-main-content'>
                <p className='c-white rtl f-16 mb-30 bold xs-d-none l-d-block'>پیکر بندی</p>
                <div className='w-100 config-card-container d-flex flex-column w-100'>
                    <div className='w-100 rtl pb-13 config-card-head mb-13'>
                        <p className='c-main bold f-14 rtl mb-2'>سرویس طلایی</p>
                        <p className='c-white-50 f-12 rtl'>سه گیگ فضا</p>
                    </div>
                    <div>
                        <p className='c-white-50 f-12 rtl config-card-item'>پهنای باند نامحدود</p>
                        <p className='c-white-50 f-12 rtl config-card-item'>کنترل پنل سی پنل</p>
                        <p className='c-white-50 f-12 rtl config-card-item'>تحویل آنی</p>
                        <p className='c-white-50 f-12 rtl config-card-item'>ایمیل نامحدود</p>
                        <p className='c-white-50 f-12 rtl config-card-item'>FTP نامحدود</p>
                        <p className='c-white-50 f-12 rtl config-card-item'>MySQL نامحدود</p>
                        <p className='c-white-50 f-12 rtl config-card-item'>Domain Addon نامحدود</p>
                        <p className='c-white-50 f-12 rtl config-card-item'>Domain Park نامحدود</p>
                        <p className='c-white-50 f-12 rtl config-card-item'>ساب دامین نامحدود</p>
                    </div>
                    <div className='w-100 mb-14'>
                        <p className='c-white bold rtl'>دوره پرداخت را انتخاب کنید</p>
 
                    </div>
                    <div className="position-relative">
                        <div onClick={opendropconfig} className='pointer  config-drop-card d-flex flex-row-reverse justify-content-between align-items-center'>
                            <p className='rtl f-12 c-white-70'>{num} تومان ماهانه</p><p><img src={down} /></p>
                        </div>
                        <div id="dropconfig" className='w-100 position-absolute drop-config-close'>
                                <p onClick={()=>{setNum("20,000");opendropconfig();}} className='pointer c-white-70 rtl f-12'>
                                    {"20,000"} تومان ماهانه  
                                </p>
                                <p onClick={() => {setNum("30,000");opendropconfig();}} className='pointer c-white-70 rtl f-12'>
                                    {"30,000"} تومان ماهانه       
                                </p>
                                <p onClick={() => {setNum("40,000");opendropconfig();}} className='pointer c-white-70 rtl f-12'>
                                    {"40,000"} تومان ماهانه  
                                </p>
                            </div>
                    </div>
                </div>
            </div>
            <div className='xs-d-none l-d-block drop-cards-config'>
                <HostingDropCat id="catconfig" />
                <div className='mb-66'></div>
                <HostingDropAct id="actconfig" />
            </div>
        </div>
        <div className="div-shrinker-2">

        </div>
        </>
    );
}

export default HostingConfig;