import './HeaderDomain.css'
import modem from '../../Images/modem.png';
import leftarrow from '../../Images/leftarrowwhite.png';
import hamber from '../../Images/hamber.png';
import {Link} from 'react-router-dom';

function HeaderDomain () {
    return (
        <div className='container-fluid domain-panel d-flex justify-content-between align-items-center'>
            <div className='header-panel-left'>
                <Link className='link1' to="/"><img src={leftarrow} /></Link>
                <img className="ml-35" src={hamber} />
            </div>
            <div className='d-flex align-items-center'>
                <p className='header-panel-text'>ثبت دامنه</p>
                <img className='d-block header-panel-icon' src={modem} />
            </div>
        </div>
    );
}

export default HeaderDomain;