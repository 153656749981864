import './HostingDropCat.css';
import cat from '../../Images/cat.png';
import up from '../../Images/up.png';
import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

function HostingDropCat(props){
    const [open, setOpen] = useState(false);

    const openBox = () => {
        if(!open){
            document.getElementById(props.id).classList.add('hosting-drop-cat-open');
            setOpen(true);
        }else{
            document.getElementById(props.id).classList.remove('hosting-drop-cat-open');
            setOpen(false);
        }
    }

    return (
        <div className='hosting-drop-cat' id={props.id}>
            <div onClick={openBox} className='hosting-drop-cat-header pointer d-flex flex-row justify-content-between align-items-center'>
                <div>
                    <img src={up} className='hosting-drop-cat-up'/>
                </div>
                <div className='d-flex flex-row justify-content-between align-items-center'>
                    <div>
                        <p className='hosting-drop-cat-text c-white bold'>دسته بندی</p>
                    </div>
                    <div>
                        <img src={cat} className="hosting-drop-cat-icon"/>
                    </div>
                </div>
            </div> 
            <div className='hosting-drop-cat-main d-flex flex-column align-items-end w-100'>
                <div>
                    <p className='d-flex align-items-center hosting-drop-cat-main-item'><span className='c-white'><Link className='link1' to="/hosting-plan/shop">هاست لینوکس</Link></span><span className='circle-orange'></span></p>
                </div>
                <div>
                    <p className='d-flex align-items-center hosting-drop-cat-main-item'><span className='c-white'><Link className='link1' to="/hosting-plan/shop">هاست ابری</Link></span><span className='circle-orange'></span></p>
                </div>
                <div>
                    <p className='d-flex align-items-center hosting-drop-cat-main-item'><span className='c-white'><Link className='link1' to="/hosting-plan/shop">هاست رایگان</Link></span><span className='circle-orange'></span></p>
                </div>
                <div>
                    <p className='d-flex align-items-center hosting-drop-cat-main-item'><span className='c-white'><Link className='link1' to="/hosting-plan/shop">هاست دانلود</Link></span><span className='circle-orange'></span></p>
                </div>
            </div>
        </div>
    );
}

export default HostingDropCat;