import './DropBuy.css';
import arrowLeftB from '../../Images/arrowleftb.png';

function DropBuy(){

    return (
        <div className='hosting-drop-buy mt-20' id="dropbuy">
            <div className='hosting-drop-buy-header c-white bold  d-flex flex-row justify-content-center align-items-center'>
                <p>خلاصه سفارش</p>
            </div>
            <div className='hosting-drop-buy-main-in d-flex flex-column align-items-end w-100'>
                <div className="w-100  mb-13 drop-buy-head">
                    <div className="w-100 d-flex">
                        <p className="c-main f-16 bold w-100 text-align-center">
                            سرویس طلایی
                        </p>
                    </div>
                    <div className="w-100 d-flex  mb-8">
                        <p className="c-main f-16 bold w-100 text-align-center">
                            هاست لینوکس | سه گیگ
                        </p>
                    </div>
                </div>
                <div className="w-100 drop-buy-item-container">
                    <div className="d-flex flex-row-reverse justify-content-between w-100 mb-30"> 
                        <span className="f-12 c-white-70 flex-filld-block"><span className="d-flex align-items-center f-12 c-white-70">سرویس طلایی سه گیگ <span className="circle-orange d-block ml-8"></span></span></span>
                        <span className='d-block c-white-70 f-12 d-flex'><span className='d-block mr-4'>تومان</span><span className='d-block'>300,000</span></span>
                    </div>
                    <div className="d-flex flex-row-reverse justify-content-between w-100 mb-30">
                        <span className="f-12 c-white-70 flex-filld-block"><span className="d-flex align-items-center f-12 c-white-70">ماهانه <span className="circle-orange d-block ml-8"></span></span></span>
                        <span className='d-block c-white-70 f-12 d-flex'><span className='d-block mr-4'>تومان</span><span className='d-block'>30,000</span></span>
                    </div>
                    <div className="d-flex flex-row-reverse justify-content-between w-100 mb-30">
                        <span className="f-12 c-white-70 flex-filld-block"><span className="d-flex align-items-center f-12 c-white-70">هزینه نصب <span className="circle-orange d-block ml-8"></span></span></span>
                        <span className='d-block c-white-70 f-12 d-flex'><span className='d-block mr-4'>تومان</span><span className='d-block'>0</span></span>
                    </div>
                    <div className="d-flex flex-row-reverse justify-content-between w-100 mb-30">
                        <span className="f-12 c-white-70 flex-filld-block"><span className="d-flex align-items-center f-12 c-white-70">تخفیف <span className="circle-orange d-block ml-8"></span></span></span>
                        <span className='d-block c-white-70 f-12 d-flex'><span className='d-block mr-4'>تومان</span><span className='d-block'>0</span></span>
                    </div>
                </div>
                <div className="py-18 w-100">
                    <p className="text-align-left c-white bold d-flex f-23 w-100">
                        <span>تومان</span><span className="ml-4">300,000</span>
                    </p>
                    <p  className="text-align-left w-100 c-main">
                        قابل پرداخت
                    </p>
                </div>
                <div>
                    <button className="btn-white-info"><span><img  className="btn-info-icon" src={arrowLeftB} /></span>ادامه</button>
                </div>
            </div>
        </div>
    );
}

export default DropBuy;