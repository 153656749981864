import './HostingTable.css';

function HostingTable(){
    return (
        <table  className='hosting-table rtl c-white'>
            <tr className='hosting-table-header'> 
                <th>دامنه</th>
                <th>ثبت</th>
                <th>انتقال</th>
                <th>تمدید</th>
            </tr>
            <tr>
                <td>.com</td>
                <td><span>295.000</span>تومان سالانه</td>
                <td><span>295.000</span>تومان سالانه</td>
                <td><span>295.000</span>تومان سالانه</td>
            </tr>
            <tr>
                <td>.info</td>
                <td><span>295.000</span>تومان سالانه</td>
                <td><span>295.000</span>تومان سالانه</td>
                <td><span>295.000</span>تومان سالانه</td>
            </tr>
        </table>
    );
}

export default HostingTable;