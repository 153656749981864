import './PanelCard.css';
import faqsWhite from '../../Images/faqsWhite.png';

function PanelCard(props) {
    return (
        <div className='panel-card-container d-flex justify-content-end align-items-center'>
            <div className='panel-card-text d-flex flex-column justify-content-center align-items-end'>
                <p className='c-white bold panel-card-content'>{props.text}</p>
                <p className='c-white panel-card-number'>0</p>
            </div>
            <div className='panel-card-icon d-flex justify-content-center align-items-center'>
                <img src={faqsWhite} />
            </div>
        </div>
    );
}

export default PanelCard;