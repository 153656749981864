import HostingCardMain from '../../Components/HostingCardMain/HostingCardMain';
import HostingCardRight from '../../Components/HostingCardRight/HostingCardRight';
import HostingDropAct from '../../Components/HostingDropAct/HostingDropAct';
import HostingDropCat from '../../Components/HostingDropCat/HostingDropCat';
import HostingForm from '../../Components/HostingForm/HostingForm';
import HostingSelect from '../../Components/HostingSelect/HostingSelect';
import HostingTable from '../../Components/HostingTable/HostingTable';
import HeaderDomain from '../HeaderDomain/HeaderDomain';
import './HostingDomain.css';

function HostingDomain(){
    return (
    <>
        <div className='xs-d-block l-d-none'>
            <HeaderDomain />
            <div className='space-host-6'></div>
        </div>
        <div className='container-xl hosting-domain-container d-flex flex-row justify-content-between'>
            <div className='d-flex flex-column w-100'>
                <div className='mb-29 w-100 d-flex flex-column align-items-end'>
                    <p className='xs-d-none l-d-block c-white bold mb-6'>ثبت دامنه</p>
                    <p className='c-white-70 rtl d-flex flex-column flex-lg-row'><span>نام جدید برای دامنه خود پیدا کنید</span><span>نام دامنه یا کلید واژه ای را وارد کنید تا امکان ثبت آن را بررسی کنیم</span></p>
                </div>
                <div>
                    <div className='hosting-form-con mb-29'>
                        <HostingForm />
                    </div>

                </div>
                <div className='w-100 mb-17'>
                    <p className='c-white rtl bold'>Browse Extensions By Category</p>
                </div>
                <div className='mb-24 w-100 d-flex justify-content-end'>
                    <HostingSelect />
                </div>
                <div className='mb-22 overflow'>
                    <HostingTable />
                </div>
                <div className='d-flex flex-column-reverse flex-lg-row justify-content-between  mb-30'>
                    <HostingCardMain header="دامنه خود را به سیستم ما انتقال دهید" body="همینکه دامنه خودرا انتقال دهید تا یک سال نیز تمدید گردد" btn="انتقال دامنه"/>
                    <div className='xs-mb-20 l-mb-0 ml-23'></div>
                    <HostingCardRight />
                </div>
            </div>
            <div className='xs-d-none l-d-block drop-container'>
                <HostingDropCat id="catdomain"/>
                <div className='mb-66'></div>
                <HostingDropAct id="actdomain"/>
            </div>
        </div> 
    </>
    );
}

export default HostingDomain;