// App.js
import './App.css';
// router
import { BrowserRouter, Routes, Route } from "react-router-dom";
// pages
import PageOne from './Pages/PageOne/PageOne.js';
import PageTwo from './Pages/PageTwo/PageTwo.js';
import PageThree from './Pages/PageThree/PageThree.js';
import PageFour from './Pages/PageFour/PageFour.js';
import PageFive from './Pages/PageFive/PageFive.js';
import PageSix from './Pages/PageSix/PageSix.js';
import PageSeven from './Pages/PageSeven/PageSeven';
import SignUp from './Pages/SignUp/SignUp.js';
import Login from './Pages/Login/Login.js';
import Hosting from './Pages/Hosting/Hosting.js';
import HostingView from './Pages/HostingView/HostingView';
import Digital from './Pages/Digital/Digital';
import Instagram from './Pages/Instagram/Instagram';
import Design from './Pages/Design/Design';
import WebDesign from './Pages/WebDesign/WebDesign';
import Application from './Pages/Application/Application';
import HostingPanel from "./sections/HostingPanel/HostingPanel";
import HostingDomain from "./sections/HostingDomain/HostingDomain";
import HostingConfig from "./sections/HostingConfig/HostingConfig";
import HostingDomainChoose from "./sections/HostingDomainChoose/HostingDomainChoose";
import HostingShop from "./sections/HostingShop/HostingShop";
import HostingBuy from "./sections/HostingBuy/HostingBuy";
import InstaPopUp from './Pages/InstaPopUp/InstaPopUp';
import Dsh from './Pages/Iframes/Dsh/Dsh';
import DshLogin from './Pages/Iframes/Login/Login';
import { useEffect } from 'react';

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route index element={<PageOne />} />
          <Route path="/login" element={<DshLogin />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/blog" element={<PageSix />} />
          <Route path="/contact" element={<PageTwo />} />
          <Route path="/about" element={<PageThree />} />
          <Route path="/service" element={<PageFour />} />
          <Route path="/blog/:slug" element={<PageSeven />} />
          <Route path="/FAQs" element={<PageFive />} />
          {/* <Route path='/hosting' element={<HostingView />} /> */}
          <Route path='/hosting' element={<Dsh />} />
          <Route path='/design' element={<Design />} />
          <Route path='/web-design' element={<WebDesign />} />
          <Route path='/application' element={<Application />} />
          <Route path='/contact' element={<Instagram />} />
          <Route path='/crypto' element={<Digital />} />
          <Route path='/social/instagram' element={<InstaPopUp />} />
          <Route path="/hosting-plan" element={<Hosting />}>
              <Route path='' element={<HostingPanel />} />
              <Route path='domain' element={<HostingDomain />} />
              <Route path='buy' element={<HostingBuy />} />
              <Route path='config' element={<HostingConfig />} />
              <Route path='choose' element={<HostingDomainChoose />} />
              <Route path='shop' element={<HostingShop />} />
          </Route>
          <Route path='*' element={<PageOne />} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
