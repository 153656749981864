import "./Digital.css";
import tick from "../../Images/tick.png";
import digitalInfo from "../../Images/digital-info.png";
import Locker from "../../Components/Locker/Locker";
import btc from "../../Images/btc.png";
import down from "../../Images/arrowdown.png";
import ttr from "../../Images/ttr.png";
import Improve from "../../sections/Improve/Improve.js";
import MainForm from "../../sections/MainForm/MainForm.js";
import Footer from "../../sections/Footer/Footer.js";
import Header from "../../sections/Header/Header.js";
import BannerArz from "../../sections/BannerArz/BannerArz";
import HeaderMob from "../../sections/HeaderMob/HeaderMob";
import MainBtnOpen from "../../Components/MainBtnOpen/MainBtnOpen";
import ArzAmar from "../../Components/ArzAmar/ArzAmar";
import bannerArz from "../../Images/bannerArz.png";
import { useState, useEffect } from "react";

function Digital() {
  const [d2name, setD2name] = useState("BITCOIN");
  const [d3name, setD3name] = useState("TETHER");

  const [d2, setD2] = useState(false);
  const [d3, setD3] = useState(false);

  const opend2 = () => {
    if (!d2) {
      document.getElementById("d2").classList.add("droper-open");
      document.getElementById("d2").classList.remove("droper-close");
      setD2(true);
    } else {
      document.getElementById("d2").classList.remove("droper-open");
      document.getElementById("d2").classList.add("droper-close");
      setD2(false);
    }
  };
  const opend3 = () => {
    if (!d3) {
      document.getElementById("d3").classList.add("droper-open");
      document.getElementById("d3").classList.remove("droper-close");
      setD3(true);
    } else {
      document.getElementById("d3").classList.remove("droper-open");
      document.getElementById("d3").classList.add("droper-close");
      setD3(false);
    }
  };

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <>
      <div className=" xs-d-none lg-d-block digital-container">
        <Header />
        <BannerArz />
        <div className="space-dig-2"></div>
        <div id="arz" className="xs-d-none l-d-flex container d-flex justify-content-between">
          <Locker
            title="دریافت کارمزد پایین"
            caption="کارمزد پایین و منصفانه یکی از ویژگی های خدمات مربوط به رمز ارز تیم عماد است."
          />
          <Locker
            title="امنیت و محرمانگی بالا"
            caption="محتوا کارت دوم: ما هیچ کدام از اطلاعات کاربران خود مانند اطلاعات بانکی و اطلاعات تماس را نزد هیچ فرد و یا سازمانی افشا نمیکنیم. همچنین در حفظ امنیت کاربران خود و اطلاعاتشان کوشا هستیم."
          />
          <Locker
            title="خرید و فروش با قیمت لحظه‌ای"
            caption="محتوا کارت یک: با اطمینان با قیمت هایی که هر لحظه در حال بروز رسانی هستند خرید و فروش کنید و نگرانی بابت قدیمی بودن قیمت ها نداشته باشید."
          />
        </div>
        <div className="space-dig-3"></div>
        <div className="xs-d-none l-d-flex bord-digit mb-150 justify-content-end align-items-center">
          <div className="d-flex flex-column-reverse">
            <div className="rtl d-flex arz-amar-content justify-content-start">
              <div className="ml-15">
                <ArzAmar />
              </div>
              <div className="ml-15">
                <ArzAmar />
              </div>
              <div className="ml-15">
                <ArzAmar />
              </div>
              <div className="ml-15">
                <ArzAmar />
              </div>
            </div>
            <div className="d-flex flex-row-reverse justify-content-start align-items-start arz-amar-header">
              <div className="arz-amar-header1">
                <p className="f-17 c-white bold">قیمت لحظه ای ارز ها</p>
                <div className="arz-amar-orange-box"></div>
              </div>
              <div>
                <p className="c-main f-14">مشاهده همه</p>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column align-items-end ml-60">
            <div className="w-100 mb-20">
              <p className="c-white bold f-16 rtl">مبدل ارز دیجیتال</p>
            </div>
            <div className="w-100 d-flex flex-column align-items-end">
              <div className="d-flex mb-10">
                <div className=" mr-10 change-input-1 d-flex justify-content-between align-items-center">
                  <input className="c-white f-14 input-ch" />
                  <p className="c-white-30 f-14">BTC</p>
                </div>
                <div
                  onClick={opend2}
                  className="pointer position-relative change-drop-1 d-flex justify-content-between align-items-center"
                >
                  <div className="">
                    <img className=" change-drop-down" src={down} />
                  </div>
                  <div className="">
                    <p className=" c-white f-14">{d2name}</p>
                  </div>
                  <div className="pointer">
                    <img className="" src={btc} />
                  </div>
                  <div id="d2" className="droper-close">
                    <div className="item">
                      <p
                        onClick={() => {
                          setD2name("TETHER");
                        }}
                        className="c-white"
                      >
                        TETHER
                      </p>
                    </div>
                    <div className="item">
                      <p
                        onClick={() => {
                          setD2name("SHIBA");
                        }}
                        className="c-white"
                      >
                        SHIBA
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div className=" mr-10 change-input-1 d-flex justify-content-between align-items-center">
                  <p className="c-white f-14">5684</p>
                  <p className="c-white-30 f-14">USDT</p>
                </div>
                <div>
                  <div
                    onClick={opend3}
                    className="pointer position-relative change-drop-1 d-flex justify-content-between align-items-center"
                  >
                    <div>
                      <img className="change-drop-down" src={down} />
                    </div>
                    <div>
                      <p className="c-white f-14">{d3name}</p>
                    </div>
                    <div>
                      <img src={ttr} />
                    </div>
                    <div id="d3" className="droper-close">
                      <div className="item">
                        <p
                          onClick={() => {
                            setD3name("BITCOIN");
                          }}
                          className="c-white"
                        >
                          BITCOIN
                        </p>
                      </div>
                      <div className="item">
                        <p
                          onClick={() => {
                            setD3name("SHIBA");
                          }}
                          className="c-white"
                        >
                          SHIBA
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="change-btn pointer">
              <p>تبدیل</p>
            </div>
          </div>
        </div>
        <div className="space-dig-3"></div>
        <div className="xs-d-none l-d-block container">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-center align-items-center">
              <img className="info-one-img" src={digitalInfo} />
            </div>
            <div className="d-flex flex-column justify-content-center align-items-end info-content h-100">
              <div className="position-relative">
                <p className="info-header bold c-white">
                  با اطمینان خرید و فروش کنید
                </p>
                <p className="orange-box position-absolute z-n-1"></p>
              </div>
              <div>
                <p className="info-en c-white-70">BUY AND SELL EASILY</p>
              </div>
              <div>
                <p className="info-txt rtl c-white-70">
                  اگر قصد سرمایه گذاری در بازار کرایپوکارنسی دارید میتوانید به
                  ما اعتماد کنید. ما با سال ها سابقه در این زمینه میتوانیم یک
                  گزینه مناسب پیش رو شما باشیم.
                </p>
              </div>
              <div>
                <p className="info-list c-white-70">
                  سرعت عمل بالا{" "}
                  <span>
                    <img src={tick} />
                  </span>
                </p>
              </div>
              <div>
                <p className="info-list c-white-70">
                  امنیت و محرمانگی اطلاعات{" "}
                  <span>
                    <img src={tick} />
                  </span>
                </p>
              </div>
              <div>
                <p className="info-list c-white-70">
                  پرداخت برداشتی های شما در کوتاه ترین زمان ممکن{" "}
                  <span>
                    <img src={tick} />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="space-dig-4"></div>
        <div className="xs-d-none l-d-block">
          <Improve />
          <div className="space-dig-5"></div>
          <div className="container">
            <MainForm />
          </div>
          <div className="space-dig-3"></div>
          <Footer />
        </div>
      </div>

      <div className="xs-d-block lg-d-none arzdigital-mobile-container">
        <div className="arzdigital-header-mobile">
          <HeaderMob />
        </div>
        <div className="arz-margin">
          <div className="arzdigital-banner-mobile">
            <img src={bannerArz} />
          </div>
          <div className="arzdigital-header1-mobile">
            <p>خرید و فروش ارز های دیجیتال</p>
          </div>
          <div className="arzdigital-header2-mobile">
            <p>و خدمات ارزی تبادل و تبدیل پول</p>
          </div>
          <div className="arzdigital-header3-mobile">
            <p>خدمات حرفه ای ارزی</p>
          </div>
          <div className="arzdigital-text-mobile">
            <p>
              خدمات کسب و کار خودرا برای حرفه‌ای تر شدن به تیم ما بسپارید.ما با
              ارائه خدمات سلح بالا از طراحی سایت تا خدمات برندینگ به شما کمک
              خواهیم کرد
            </p>
          </div>
          <div className="arzdigital-main-btn-mobile position-absolute">
            <MainBtnOpen link="/" text="بیشتر بدانید" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Digital;
