import { useState } from 'react';
import HostingDropAct from '../../Components/HostingDropAct/HostingDropAct';
import HostingDropCat from '../../Components/HostingDropCat/HostingDropCat';
import './HostingDomainChoose.css';
import HeaderDomain from '../../sections/HeaderDomain/HeaderDomain';

function HostingDomainChoose() {
    const [chooseOne, setChooseOne] = useState(true);
    const [chooseTwo, setChooseTwo] = useState(false);
    const [chooseThree, setChooseThree] = useState(false);

    const chooseUpdate = (id) => {
        switch(id){
            case 'item1':
                setChooseOne(true);
                setChooseTwo(false);
                setChooseThree(false);
            break;
            case 'item2':
                setChooseOne(false);
                setChooseTwo(true);
                setChooseThree(false);
            break;
            case 'item3':
                setChooseOne(false);
                setChooseTwo(false);
                setChooseThree(true);
            break;
        }
    }

    return (
        <div className='container hosting-domain-choose-container d-flex  flex-row justify-content-between'>
            <div className='w-100'>
                <div className='xs-d-block l-d-none'>
                    <HeaderDomain />
                    <div className='header-bottom'></div>
                </div>    
    
                <div className='w-100 mb-30'>
                    <p className='c-white rtl f-16'>یک دامنه انتخاب کنید</p>
                </div>
                <div>
                    <div onClick={() => {chooseUpdate('item1')}} className='pointer w-100 d-flex flex-row-reverse justify-content-start align-items-cente choose-item-container'>
                        <div className={(chooseOne ? "choose-orange-circle" : "choose-black-circle")}>
                        </div>
                        <div>   
                            <p className='c-white-70 rtl'>ثبت دامنه جدید</p>
                        </div>
                    </div>
                    <div onClick={() => {chooseUpdate('item2')}} className='pointer w-100 d-flex flex-row-reverse justify-content-start align-items-center choose-item-container'>
                        <div className={(chooseTwo ? "choose-orange-circle" : "choose-black-circle")}>

                        </div>
                        <div>   
                            <p className='c-white-70 rtl'>انتقال دامنه از شرکت دیگر</p>
                        </div>
                    </div>
                    <div onClick={() => {chooseUpdate('item3')}} className='pointer w-100 d-flex flex-row-reverse justify-content-start align-items-center choose-item-container'>
                        <div className={(chooseThree ? "choose-orange-circle" : "choose-black-circle")}>

                        </div>
                        <div>
                            <p className='c-white-70 rtl'>از دامنه ای که قبلا ثبت کرده ام استفاده میکنم</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='xs-d-none l-d-block drop-card-choose-container'>
                <HostingDropCat id="choosecat" />
                <div className='mb-70'></div>
                <HostingDropAct id="chooseact" />
            </div>
            <div className='div-shrinker-4'>

            </div>
        </div>
    );
}

export default HostingDomainChoose;